var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { set, flow, map, transformOptions } from '../../utils';
export var Y_FIELD_KEY = '__stock-range__';
export var TREND_FIELD = 'trend';
export var TREND_UP = 'up';
export var TREND_DOWN = 'down';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    var transformData = function (params) {
        var _a = params.options, data = _a.data, yField = _a.yField;
        params.options.data = map(data, function (item) {
            var obj = item && __assign({}, item);
            if (Array.isArray(yField) && obj) {
                var open_1 = yField[0], close_1 = yField[1], high = yField[2], low = yField[3];
                obj[TREND_FIELD] = obj[open_1] <= obj[close_1] ? TREND_UP : TREND_DOWN;
                obj[Y_FIELD_KEY] = [obj[open_1], obj[close_1], obj[high], obj[low]];
            }
            return obj;
        });
        return params;
    };
    /**
     * 图表差异化处理
     */
    var init = function (params) {
        var options = params.options;
        var xField = options.xField, yField = options.yField, fallingFill = options.fallingFill, risingFill = options.risingFill;
        var open = yField[0], close = yField[1], high = yField[2], low = yField[3];
        params.options.children = map(params.options.children, function (child, index) {
            var isShadow = index === 0;
            return __assign(__assign({}, child), { tooltip: {
                    title: function (d) { return (d[xField] instanceof Date ? d[xField].toLocaleString() : d[xField]); },
                    items: [{ field: high }, { field: low }, { field: open }, { field: close }],
                }, encode: __assign(__assign({}, (child.encode || {})), { y: isShadow ? [high, low] : [open, close], color: function (d) { return Math.sign(d[close] - d[open]); } }), style: __assign(__assign({}, (child.style || {})), { lineWidth: isShadow ? 1 : 10 }) });
        });
        delete options['yField'];
        params.options.legend = {
            color: false,
        };
        if (fallingFill) {
            set(params, 'options.scale.color.range[0]', fallingFill);
        }
        if (risingFill) {
            set(params, 'options.scale.color.range[2]', risingFill);
        }
        return params;
    };
    return flow(transformData, init, transformOptions)(params);
}
