export * as G2 from '@antv/g2';
export * from './types';
import { Base } from './plots/base';
import { Area } from './plots/area';
import { Bar } from './plots/bar';
import { Column } from './plots/column';
import { DualAxes } from './plots/dual-axes';
import { Funnel } from './plots/funnel';
import { Line } from './plots/line';
import { Pie } from './plots/pie';
import { Scatter } from './plots/scatter';
import { Radar } from './plots/radar';
import { Stock } from './plots/stock';
import { TinyLine } from './plots/tiny-line';
import { TinyArea } from './plots/tiny-area';
import { TinyColumn } from './plots/tiny-column';
import { TinyProgress } from './plots/tiny-progress';
import { TinyRing } from './plots/tiny-ring';
import { Rose } from './plots/rose';
import { Waterfall } from './plots/waterfall';
import { Histogram } from './plots/histogram';
import { Heatmap } from './plots/heatmap';
import { Box } from './plots/box';
import { Sankey } from './plots/sankey';
import { Bullet } from './plots/bullet';
import { Gauge } from './plots/gauge';
import { Liquid } from './plots/liquid';
import { WordCloud } from './plots/wordCloud';
import { Treemap } from './plots/treemap';
import { RadialBar } from './plots/radial-bar';
import { CirclePacking } from './plots/circlePacking';
import { Violin } from './plots/violin';
import { BidirectionalBar } from './plots/bidirectional-bar';
import { Venn } from './plots/venn';
import { Mix } from './plots/mix';
import { Sunburst } from './plots/sunburst';
export var Plots = {
    Base: Base,
    Line: Line,
    Column: Column,
    Pie: Pie,
    Area: Area,
    Bar: Bar,
    DualAxes: DualAxes,
    Funnel: Funnel,
    Scatter: Scatter,
    Radar: Radar,
    Rose: Rose,
    Stock: Stock,
    TinyLine: TinyLine,
    TinyArea: TinyArea,
    TinyColumn: TinyColumn,
    TinyProgress: TinyProgress,
    TinyRing: TinyRing,
    Waterfall: Waterfall,
    Histogram: Histogram,
    Heatmap: Heatmap,
    Box: Box,
    Sankey: Sankey,
    Bullet: Bullet,
    Gauge: Gauge,
    Liquid: Liquid,
    WordCloud: WordCloud,
    Treemap: Treemap,
    RadialBar: RadialBar,
    CirclePacking: CirclePacking,
    Violin: Violin,
    BidirectionalBar: BidirectionalBar,
    Venn: Venn,
    Mix: Mix,
    Sunburst: Sunburst,
};
